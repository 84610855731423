<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <!-- <h5 class="mb-0">Cierre guia</h5> -->
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Cierre guia</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section
          class="content"
          v-if="$store.getters.can('hidrocarburos.cierreGuias.index')"
        >
          <div class="container-fluid">
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                CIERRE GUÍA
              </legend>
              <div class="row" v-if="visible == 0">
                <div class="col-12 col-sm-6 col-md-2">
                  <div class="info-box">
                    <button
                      class="info-box-icon bg-gradient-info elevation-1"
                      type="button"
                      @click="exportarCierreGuia()"
                    >
                      <i class="fas fa-file-csv"></i>
                    </button>
                    <div class="info-box-content">
                      <span class="info-box-text">Generar Plantilla</span>
                    </div>
                  </div>
                </div>
                <!-- Adjuntar Cierre / Importar / Eliminar -->
                <div class="col-12 col-sm-6 col-md-4">
                  <div class="info-box">
                    <div class="form-group">
                      <label for=""></label>
                      <input
                        v-if="file == null"
                        type="file"
                        id="file"
                        ref="file"
                        v-on:change="elegirDirectorio()"
                        accept=".XLSX, .CSV"
                        class="form-control btn btn-secundary col-sm-12"
                        style="color: #fff"
                      />
                    </div>
                    <button
                      v-if="file != null"
                      type="button"
                      class="info-box-icon bg-gradient-primary elevation-1"
                      @click="importarCierreGuia()"
                    >
                      <i class="fas fa-upload"></i>
                    </button>
                    <div class="info-box-content" v-if="file != null">
                      <span class="info-box-text">Importar Cierre</span>
                    </div>
                    <button
                      type="button"
                      class="info-box-icon bg-gradient-danger elevation-1"
                      v-if="file != null"
                      @click="eliminarLink()"
                    >
                      <i class="fas fa-trash-alt"></i>
                    </button>
                    <div class="info-box-content" v-if="file != null">
                      <span class="info-box-text">Eliminar Cierre</span>
                    </div>
                  </div>
                </div>
                <!--Cierre Individual  -->
                <div
                  class="col-12 col-sm-6 col-md-2"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.cierreGuias.cierreIndividual'
                    )
                  "
                >
                  <div class="info-box">
                    <button
                      type="button"
                      class="info-box-icon bg-gradient-navy elevation-1"
                      data-toggle="modal"
                      data-target="#modal-form-cierre-individual"
                    >
                      <i class="fas fa-file-contract"></i>
                    </button>
                    <div class="info-box-content">
                      <span class="info-box-text">Cierre Individual</span>
                    </div>
                  </div>
                </div>
                <!-- Autorización Cierre -->
                <div
                  class="col-12 col-sm-6 col-md-2"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.cierreGuias.aprobarCierre'
                    )
                  "
                >
                  <div class="info-box">
                    <button
                      type="button"
                      class="info-box-icon bg-gradient-purple elevation-1"
                      @click="aprobarCierre()"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <div class="info-box-content">
                      <span class="info-box-text">Aprobar Cierre</span>
                    </div>
                  </div>
                </div>
                <!-- Reporte Excel -->
                <div
                  class="col-12 col-sm-6 col-md-2"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.cierreGuias.cierreIndividual'
                    )
                  "
                >
                  <div class="info-box">
                    <button
                      type="button"
                      class="info-box-icon bg-gradient-success elevation-1"
                      data-toggle="modal"
                      data-target="#modal-form-cierre-guia-export"
                      v-if="
                        $store.getters.can('hidrocarburos.cierreGuias.export')
                      "
                    >
                      <i class="far fa-file-excel"></i>
                    </button>
                    <div class="info-box-content">
                      <span class="info-box-text">Reporte Cierre</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Cierre en el sistema -->
              <div class="row" v-if="visible == 1">
                <div class="col-12 col-sm-6 col-md-4">
                  <div class="info-box" v-if="cumple == 1">
                    <button
                      type="button"
                      class="info-box-icon bg-gradient-cyan elevation-1"
                      @click="subirAlSistema()"
                    >
                      <i class="fas fa-file-upload"></i>
                    </button>
                    <div class="info-box-content">
                      <span class="info-box-text"
                        >Subir el cierre al sistema</span
                      >
                    </div>
                  </div>
                  <div class="info-box" v-else>
                    <button
                      type="button"
                      class="info-box-icon bg-gradient-navy elevation-1"
                      @click="cargarCierre()"
                    >
                      <i class="fas fa-spinner"></i>
                    </button>
                    <div class="info-box-content">
                      <span class="info-box-text">Recargar diferencia</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <div class="info-box">
                    <button
                      type="button"
                      class="info-box-icon bg-gradient-warning elevation-1"
                      @click="descartarErrores()"
                    >
                      <i class="fas fa-exclamation"></i>
                    </button>
                    <div class="info-box-content">
                      <span class="info-box-text"
                        >Descartar los registros con errores</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <div class="info-box">
                    <button
                      type="button"
                      class="info-box-icon bg-gradient-danger elevation-1"
                      @click="descartarTotal()"
                    >
                      <i class="fas fa-times-circle"></i>
                    </button>
                    <div class="info-box-content">
                      <span class="info-box-text"
                        >Descartar la importacion totalmente</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- Tabla de los datos de los cierres -->
              <div class="card" style="display: inherit">
                <div v-if="visible == 1">
                  <div class="card-body p-0">
                    <table
                      style="font-size: 85%"
                      class="table table-bordered table-striped table-hover table-responsive table-sm text-center"
                    >
                      <thead class="thead-dark">
                        <tr>
                          <th rowspan="2" style="min-width: 20em">
                            Observación
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.observacion"
                              @change="cargarCierre()"
                            >
                              <option value="">Seleccione</option>
                              <option
                                v-for="objCierre in listasForms.listaCierre"
                                :key="objCierre.numeracion"
                                :value="objCierre.numeracion"
                              >
                                {{ objCierre.descripcion }}
                              </option>
                            </select>
                          </th>
                          <th class="p-4" rowspan="2" style="min-width: 5em">
                            N°
                          </th>
                          <th rowspan="2" style="min-width: 15em">
                            Número de guia
                            <input
                              type="number"
                              class="form-control form-control-sm"
                              v-model="filtros.nGuia"
                              placeholder="# Guia"
                              @keyup.enter="cargarCierre()"
                            />
                          </th>
                          <th rowspan="2" style="min-width: 20em">
                            Ventana descargue
                            <v-select
                              class="form-control form-control-sm p-0"
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="ventana_descargue"
                              placeholder="Ventana Descargue"
                              label="nombre"
                              :options="listasForms.ventanasDescargue"
                              @input="cargarCierre()"
                            >
                            </v-select>
                          </th>
                          <th rowspan="2" style="min-width: 15em">
                            Placa Vehiculo
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              v-model="filtros.nPlaca"
                              placeholder="Vehiculo"
                              @keyup.enter="cargarCierre()"
                            />
                          </th>
                          <th rowspan="2">
                            Empresa
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="empresa"
                              placeholder="Nombre"
                              label="razon_social"
                              :options="listasForms.empresas"
                              @input="cargarCierre()"
                            >
                            </v-select>
                          </th>
                          <th rowspan="2">
                            Producto Líquido
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="producto_liquido"
                              placeholder="Nombre"
                              label="nombre"
                              :options="listasForms.productos_liquidos"
                              @input="cargarCierre()"
                            >
                            </v-select>
                          </th>
                          <th colspan="3">Volumenes GOV</th>
                          <th colspan="3">Volumenes GSV</th>
                          <th colspan="3">Volumenes NSV</th>
                          <th colspan="3">Volumenes BSW</th>
                          <th colspan="3">Volumenes API</th>
                          <th class="p-4" rowspan="2">Temperatura</th>
                          <th class="p-4" rowspan="2" style="min-width: 8em">
                            % SAL
                          </th>
                          <th class="p-4" rowspan="2" style="min-width: 10em">
                            % Azufre
                          </th>
                          <th colspan="2">Fechas</th>
                          <th colspan="2">Novedades</th>
                          <th class="p-4" rowspan="2">Acción</th>
                        </tr>

                        <tr>
                          <th class="p-4">Cargue</th>
                          <th class="p-4">Descargue</th>
                          <th class="p-4">Diferencia</th>
                          <th class="p-4">Cargue</th>
                          <th class="p-4">Descargue</th>
                          <th class="p-4">Diferencia</th>
                          <th class="p-4">Cargue</th>
                          <th class="p-4">Descargue</th>
                          <th class="p-4">Diferencia</th>
                          <th class="p-4">Cargue</th>
                          <th class="p-4">Descargue</th>
                          <th class="p-4">Diferencia</th>
                          <th class="p-4">Cargue</th>
                          <th class="p-4">Descargue</th>
                          <th class="p-4">Diferencia</th>
                          <th>
                            Enturne
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              v-model="filtros.fecha_enturne"
                              placeholder="fecha"
                              @keyup.enter="cargarCierre()"
                            />
                          </th>
                          <th>
                            Descargue
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              v-model="filtros.fecha_descargue"
                              placeholder="fecha"
                              @keyup.enter="cargarCierre()"
                            />
                          </th>

                          <th style="min-width: 10em">
                            Novedad 1
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.novedad_1"
                              @change="cargarCierre()"
                            >
                              <option value="">Seleccione</option>
                              <option
                                v-for="novedad_1 in listasForms.novedades"
                                :key="novedad_1.numeracion"
                                :value="novedad_1.numeracion"
                              >
                                {{ novedad_1.descripcion }}
                              </option>
                            </select>
                          </th>
                          <th style="min-width: 10em">
                            Novedad 2
                            <select
                              class="form-control form-control-sm"
                              v-model="filtros.novedad_2"
                              @change="cargarCierre()"
                            >
                              <option value="">Seleccione</option>
                              <option
                                v-for="novedad_2 in listasForms.novedades"
                                :key="novedad_2.numeracion"
                                :value="novedad_2.numeracion"
                              >
                                {{ novedad_2.descripcion }}
                              </option>
                            </select>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          v-for="(data, index) in cargarCierreGuias.data"
                          :key="data.id"
                          :class="getBackgroundClass(index)"
                        >
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="[
                                data.observacion == 0
                                  ? 'badge-dark'
                                  : data.observacion == 1
                                  ? 'badge-danger'
                                  : data.observacion == 2
                                  ? 'badge-danger'
                                  : data.observacion == 3
                                  ? 'badge-dark'
                                  : data.observacion == 4
                                  ? 'badge-secondary'
                                  : data.observacion == 5
                                  ? 'badge-secondary'
                                  : data.observacion == 6
                                  ? 'badge-secondary'
                                  : data.observacion == 7 //gama de errores en validacion
                                  ? 'badge-danger'
                                  : data.observacion == 8
                                  ? 'badge-danger'
                                  : data.observacion == 9
                                  ? 'badge-danger'
                                  : data.observacion == 10
                                  ? 'badge-danger'
                                  : data.observacion == 11
                                  ? 'badge-danger'
                                  : data.observacion == 12
                                  ? 'badge-danger'
                                  : data.observacion == 13
                                  ? 'badge-danger'
                                  : data.observacion == 14
                                  ? 'badge-danger'
                                  : data.observacion == 15
                                  ? 'badge-danger'
                                  : data.observacion == 16
                                  ? 'badge-danger'
                                  : data.observacion == 17
                                  ? 'badge-danger'
                                  : data.observacion == 18
                                  ? 'badge-danger'
                                  : data.observacion == 19
                                  ? 'badge-danger'
                                  : data.observacion == 20
                                  ? 'badge-danger'
                                  : data.observacion == 21
                                  ? 'badge-danger'
                                  : data.observacion == 22
                                  ? 'badge-danger' // hasta aca van lo errores
                                  : data.observacion == 23
                                  ? 'badge-info'
                                  : data.observacion == 24
                                  ? 'badge-info'
                                  : data.observacion == 25
                                  ? 'badge-info'
                                  : data.observacion == 26
                                  ? 'badge-info'
                                  : data.observacion == 27
                                  ? 'badge-warning'
                                  : data.observacion == 28
                                  ? 'badge-success'
                                  : '',
                              ]"
                            >
                              {{ data.nEstado }}
                            </span>
                          </td>

                          <td>{{ index + 1 }}</td>

                          <td v-if="data.guia">
                            {{ data.guia.numero }}-{{
                              data.guia.digito_verificacion
                            }}
                          </td>
                          <td v-else>{{ data.guia_id }}</td>

                          <td v-if="data.guia">
                            <span
                              v-if="
                                data.observacion >= 23 && data.observacion <= 26
                              "
                              class="badge badge-danger"
                            >
                              <div
                                v-if="data.guia.viaje.ventana_descargue != null"
                              >
                                {{ data.guia.viaje.ventana_descargue.nombre }}
                              </div>
                              <div v-else>------------------</div>
                            </span>

                            <div v-else>
                              <div
                                v-if="data.guia.viaje.ventana_descargue != null"
                              >
                                {{ data.guia.viaje.ventana_descargue.nombre }}
                              </div>
                              <div v-else>-------------------</div>
                            </div>
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              data-toggle="modal"
                              data-target="#modal-cierre-cambio-ventana"
                              style="cursor: pointer"
                              v-if="
                                $store.getters.can(
                                  'hidrocarburos.cierreGuias.cambiarVentana'
                                ) &&
                                  (data.observacion >= 23 ||
                                    data.observacion < 30)
                              "
                              @click="
                                llenarModalVentana(
                                  data.guia.viaje,
                                  data.id,
                                  data.observacion
                                )
                              "
                            >
                              Cambiar
                            </button>
                          </td>
                          <td v-else>Sin guia asociada</td>

                          <td v-if="data.guia">
                            {{ data.guia.det_guias[0].vehiculo.placa }}
                          </td>
                          <td v-else>Sin guia asociada</td>
                          <td v-if="data.empresa">
                            {{ data.empresa.razon_social }}
                          </td>
                          <td v-else>Sin empresa asociada</td>
                          <td v-if="data.producto">
                            {{ data.producto.nombre }}
                          </td>
                          <td v-else>Sin producto asociado</td>

                          <td v-if="data.guia">
                            {{ data.guia.det_guias[0]["gov"] }}
                          </td>
                          <td v-else>Sin guia asociada</td>
                          <td v-if="data.barriles_gov">
                            {{ data.barriles_gov }}
                          </td>
                          <td v-else>---</td>
                          <td
                            v-if="
                              data.diferencia_gov != 100 ||
                                data.diferencia_gov != -100
                            "
                          >
                            <div
                              v-if="
                                data.diferencia_gov >= 2 ||
                                  data.diferencia_gov <= -2
                              "
                            >
                              <div v-if="data.diferencia_gov >= 2">
                                <span class="badge badge-warning">
                                  % {{ data.diferencia_gov }}</span
                                >
                              </div>
                              <div v-if="data.diferencia_gov <= -2">
                                <span class="badge badge-danger">
                                  % {{ data.diferencia_gov }}</span
                                >
                              </div>
                            </div>
                            <div v-else>% {{ data.diferencia_gov }}</div>
                          </td>
                          <td v-else>---</td>

                          <td v-if="data.guia">
                            {{ data.guia.det_guias[0]["gsv"] }}
                          </td>
                          <td v-else>Sin guia asociada</td>

                          <td>
                            <span
                              v-if="formActualizar && idActualizar == index"
                            >
                              <!-- Formulario para actualizar -->
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                id="barriles_gsv"
                                v-model="form.barriles_gsv"
                              />
                            </span>
                            <span v-else>
                              <!-- Dato nombre -->
                              {{ data.barriles_gsv }}
                            </span>
                          </td>
                          <td
                            v-if="
                              data.diferencia_gsv != 100 ||
                                data.diferencia_gsv != -100
                            "
                          >
                            <div
                              v-if="
                                data.diferencia_gsv >= 2 ||
                                  data.diferencia_gsv <= -2
                              "
                            >
                              <div v-if="data.diferencia_gsv >= 2">
                                <span class="badge badge-warning">
                                  % {{ data.diferencia_gsv }}</span
                                >
                              </div>
                              <div v-if="data.diferencia_gsv <= -2">
                                <span class="badge badge-danger">
                                  % {{ data.diferencia_gsv }}</span
                                >
                              </div>
                            </div>
                            <div v-else>% {{ data.diferencia_gsv }}</div>
                          </td>
                          <td v-else>---</td>
                          <td v-if="data.guia">
                            {{ data.guia.det_guias[0]["nsv"] }}
                          </td>
                          <td v-else>Sin guia asociada</td>
                          <td>
                            <span
                              v-if="formActualizar && idActualizar == index"
                            >
                              <!-- Formulario para actualizar -->
                              <input
                                type="number"
                                class="form-control form-control-sm"
                                id="tiempo"
                                v-model="form.barriles_nsv"
                              />
                            </span>
                            <span v-else>
                              <!-- Dato nombre -->
                              {{ data.barriles_nsv }}
                            </span>
                          </td>
                          <td
                            v-if="
                              data.diferencia_nsv != 100 ||
                                data.diferencia_nsv != -100
                            "
                          >
                            <div
                              v-if="
                                data.diferencia_nsv >= 2 ||
                                  data.diferencia_nsv <= -2
                              "
                            >
                              <div v-if="data.diferencia_nsv >= 2">
                                <span class="badge badge-warning">
                                  % {{ data.diferencia_nsv }}</span
                                >
                              </div>
                              <div v-if="data.diferencia_nsv <= -2">
                                <span class="badge badge-danger">
                                  % {{ data.diferencia_nsv }}</span
                                >
                              </div>
                            </div>
                            <div v-else>% {{ data.diferencia_nsv }}</div>
                          </td>
                          <td v-else>---</td>
                          <td v-if="data.guia">
                            {{ data.guia.det_guias[0]["bsw"] }}
                          </td>
                          <td v-else>Sin guia asociada</td>
                          <td>
                            <span>
                              <!-- Dato nombre -->
                              {{ data.porcentaje_bsw }}
                            </span>
                          </td>
                          <td
                            v-if="
                              data.diferencia_bsw != 100 ||
                                data.diferencia_bsw != -100
                            "
                          >
                            <div
                              v-if="
                                data.diferencia_bsw >= 2 ||
                                  data.diferencia_bsw <= -2
                              "
                            >
                              <div v-if="data.diferencia_bsw >= 2">
                                <span class="badge badge-warning">
                                  % {{ data.diferencia_bsw }}</span
                                >
                              </div>
                              <div v-if="data.diferencia_bsw <= -2">
                                <span class="badge badge-danger">
                                  % {{ data.diferencia_bsw }}</span
                                >
                              </div>
                            </div>
                            <div v-else>% {{ data.diferencia_bsw }}</div>
                          </td>
                          <td v-else>---</td>
                          <td v-if="data.guia">
                            {{ data.guia.det_guias[0]["api"] }}
                          </td>
                          <td v-else>Sin guia asociada</td>
                          <td>
                            <span>
                              <!-- Dato nombre -->
                              {{ data.api }}
                            </span>
                          </td>
                          <td
                            v-if="
                              data.diferencia_api != 100 ||
                                data.diferencia_api != -100
                            "
                          >
                            <div
                              v-if="
                                data.diferencia_api >= 2 ||
                                  data.diferencia_api <= -2
                              "
                            >
                              <div v-if="data.diferencia_api >= 2">
                                <span class="badge badge-warning">
                                  % {{ data.diferencia_api }}</span
                                >
                              </div>
                              <div v-if="data.diferencia_api <= -2">
                                <span class="badge badge-danger">
                                  % {{ data.diferencia_api }}</span
                                >
                              </div>
                            </div>
                            <div v-else>% {{ data.diferencia_api }}</div>
                          </td>
                          <td v-else>---</td>
                          <td>{{ data.temperatura }}</td>
                          <td>{{ data.porcentaje_sal }}</td>
                          <td>{{ data.porcentaje_azufre }}</td>
                          <td>
                            {{ data.fecha_hora_enturne_descargue }}
                          </td>
                          <td>
                            <!-- Dato nombre -->
                            {{ data.fecha_hora_descargue }}
                          </td>

                          <td v-if="data.novedad_1">
                            <div
                              v-if="listasForms.novedades[data.novedad_1 - 1]"
                            >
                              {{
                                listasForms.novedades[data.novedad_1 - 1]
                                  .descripcion
                              }}
                            </div>
                          </td>
                          <td v-else>Ninguna</td>
                          <td v-if="data.novedad_2">
                            <div
                              v-if="listasForms.novedades[data.novedad_2 - 1]"
                            >
                              {{
                                listasForms.novedades[data.novedad_2 - 1]
                                  .descripcion
                              }}
                            </div>
                          </td>
                          <td v-else>Ninguna</td>
                          <td
                            v-if="
                              data.observacion >= 1 && data.observacion <= 26
                            "
                          >
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-danger"
                                @click="descartar(data.id)"
                              >
                                Descartar
                              </button>
                            </div>
                          </td>
                          <td v-if="data.observacion == 21 && !formActualizar">
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-danger"
                                @click="descartar(data.id)"
                              ></button>
                            </div>
                            <div class="btn-group float-right"></div>
                          </td>
                          <td
                            v-if="
                              data.observacion == 21 &&
                                formActualizar &&
                                idActualizar == index
                            "
                          >
                            <div class="btn-group float-right">
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-success"
                                @click="guardarCierre(index)"
                              >
                                <i class="fas fa-save"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm bg-gradient-danger"
                                @click="sinCierre()"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                            </div>
                          </td>
                          <td v-if="data.observacion == 22">
                            Listo para subir
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer">
                    <div class="float-left" v-if="cargarCierreGuias.total">
                      <p>
                        Mostrando del <b>{{ cargarCierreGuias.from }}</b> al
                        <b>{{ cargarCierreGuias.to }}</b> de un total:
                        <b>{{ cargarCierreGuias.total }}</b> Registros
                      </p>
                    </div>
                    <div class="float-left" v-else>
                      <p>
                        <span class="badge badge-danger">
                          No hay registros para mostrar
                        </span>
                      </p>
                    </div>
                    <pagination
                      :data="cargarCierreGuias"
                      @pagination-change-page="cargarCierre"
                      :limit="5"
                      align="right"
                    ></pagination>
                  </div>
                </div>
              </div>
            </fieldset>
            <!-- Cuadro Total Cierres-->
            <div class="col-md-12" v-if="visibleCuadro == 1">
              <div class="row">
                <fieldset class="well card">
                  <legend class="well-legend text-bold bg-frontera text-light">
                    TOTALES CIERRES
                  </legend>
                  <div class="col-lg-12">
                    <div class="small-box bg-light">
                      <div class="inner">
                        <h3 style="font-size: 30px">Cierre Guia</h3>
                        <h3 style="font-size: 15px">
                          Completos:
                          <span class="badge badge-pill badge-success">
                            {{ informacion.sin_error }}
                          </span>
                        </h3>
                        <h3 style="font-size: 15px">
                          Para aprobación:
                          <span class="badge badge-pill badge-warning">
                            {{ informacion.con_diferencia }}
                          </span>
                        </h3>
                        <h3 style="font-size: 15px">
                          Error en ventana de descargue
                          <span class="badge badge-pill badge-info">
                            {{ informacion.ventana_erronea }}
                          </span>
                        </h3>
                        <h3 style="font-size: 15px">
                          Sin ventana de descargue:
                          <span class="badge badge-pill badge-info">
                            {{ informacion.sin_ventana }}
                          </span>
                        </h3>
                        <h3 style="font-size: 15px">
                          Guias con cierre masivo
                          <span class="badge badge-pill badge-dark">
                            {{ informacion.con_cierre }}
                          </span>
                        </h3>
                        <h3 style="font-size: 15px">
                          Sin cierre en guia/viaje
                          <span class="badge badge-pill badge-light">
                            {{ informacion.cierre_guia }}
                          </span>
                        </h3>
                        <h3 style="font-size: 15px">
                          Guia por aprobar
                          <span class="badge badge-pill badge-secondary">
                            {{ informacion.con_aprobacion }}
                          </span>
                        </h3>
                        <h3 style="font-size: 15px">
                          Con errores
                          <span class="badge badge-pill badge-danger">
                            {{ informacion.con_error }}
                          </span>
                        </h3>
                        <h3 style="font-size: 15px">
                          Cierre masivo por otro usuario
                          <span class="badge badge-pill badge-dark">
                            {{ informacion.otro_usuario }}
                          </span>
                        </h3>
                      </div>
                      <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                      </div>
                      <a href="#" class="small-box-footer"></a>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <button class="btn bg-secondary col-md-1" @click="back()">
                  <i class="fas fa-reply"></i><br />Volver
                </button>
              </div>
            </div>
          </div>
        </section>
        <CierreGuiaManual ref="CierreGuiaManual" />
        <CierreCambioVentana ref="CierreCambioVentana" />
      </div>
    </div>
    <CierreGuiaExport ref="CierreGuiaExport" />
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import CierreGuiaManual from "./CierreGuiaManual";
import CierreCambioVentana from "../cierreGuias/CierreCambioVentana";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import CierreGuiaExport from "./CierreGuiaExport";
export default {
  name: "CierreGuiaIndex",
  components: {
    CierreGuiaManual,
    CierreCambioVentana,
    pagination,
    Loading,
    vSelect,
    CierreGuiaExport,
  },
  data() {
    return {
      formActualizar: false,
      visibleCuadro: 0,
      informacionTotalCierre: {},
      ventana_descargue: null,
      empresa: null,
      producto_liquido: null,
      idActualizar: -1,
      cargando: false,
      form: {
        id: null,
        guia_id: null,
        barriles_gsv: null,
        barriles_nsv: null,
        api: null,
        porcentaje_bsw: null,
      },
      informacion: {
        sin_error: 0,
        con_diferencia: 0,
        sin_ventana: 0,
        ventana_erronea: 0,
        con_cierre: 0,
        con_error: 0,
        cierre_guia: 0,
        con_aprobacion: 0,
        otro_usuario: 0,
      },
      file: null,
      cierre: null,
      filtros: {
        observacion: null,
        nGuia: null,
        nPlaca: null,
        novedad_1: null,
        novedad_2: null,
        fecha_enturne: null,
        fecha_descargue: null,
      },
      visible: 0,
      usuario_id: this.$store.getters.getUser.id,
      cumple: 1,

      cargarCierreGuias: {},
      empresas: [],
      slct_empresa: [],
      listasForms: {
        novedades: [],
        listaCierre: [],
        ventanasDescargue: [],
        empresas: [],
        productos_liquidos: [],
      },
    };
  },
  validations: {
    form: {
      barriles_gsv: {
        required,
      },
      barriles_nsv: {
        required,
      },
      api: {
        required,
      },
      porcentaje_bsw: {
        required,
      },
    },
  },
  methods: {
    llenarModalVentana(viaje, cierre_id, observacion) {
      this.$refs.CierreCambioVentana.llenar_modal_cambio_ventana(
        viaje,
        cierre_id,
        observacion
      );
    },
    subirAlSistema() {
      let me = this;
      let sin_error = me.informacion.sin_error + me.informacion.con_diferencia;
      let sin_subir =
        me.informacion.sin_ventana +
        me.informacion.ventana_erronea +
        me.informacion.con_cierre +
        me.informacion.con_error +
        me.informacion.cierre_guia +
        me.informacion.con_aprobacion +
        me.informacion.otro_usuario;
      this.$swal({
        title: "Esta seguro de Guardar los cierres de guia?",
        text:
          "Cierres sin errores (Suben al sistema): " +
          sin_error +
          "\n Cierres completos: " +
          me.informacion.sin_error +
          "\n Cierres que necesitan aprobación: " +
          me.informacion.con_diferencia +
          "\nCierres que no se suben al sistema: " +
          sin_subir,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Guardar!",
      }).then((result) => {
        if (result.value) {
          me.visible = 2;
          me.$swal({
            icon: "success",
            title: "Subiendo al sistema",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          axios
            .post(
              "/api/hidrocarburos/cierreGuias/subirAlSistema/" + me.usuario_id
            )
            .then(() => {
              me.filtros.nPlaca = null;
              me.filtros.nGuia = null;
              me.filtros.observacion = null;
              me.ventana_descargue = null;
              me.filtros.novedad_1 = null;
              me.filtros.novedad_2 = null;
              me.filtros.fecha_enturne = null;
              me.filtros.fecha_descargue = null;
              me.producto_liquido = null;
              me.empresa = null;
              me.formActualizar = false;
              me.visible = 0;
              me.visibleCuadro = 1;
              me.$swal({
                icon: "success",
                title:
                  "Cierres sin errores (Suben al sistema): " +
                  sin_error +
                  "\n Cierres completos: " +
                  me.informacion.sin_error +
                  "\n Cierres que necesitan aprobación: " +
                  me.informacion.con_diferencia +
                  "\nCierres que no se suben al sistema: " +
                  sin_subir,
                showConfirmButton: true,
              });
            })
            .catch((e) => {
              this.cargando = false;
              me.visible = 1;
              me.visibleCuadro = 1;
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });

          me.cargando = false;
        }
      });
    },

    descartarErrores() {
      let me = this;
      let con_error =
        me.informacion.con_cierre +
        me.informacion.con_error +
        me.informacion.cierre_guia +
        me.informacion.con_aprobacion +
        me.informacion.otro_usuario;
      this.$swal({
        title: "Esta seguro de eliminar los  Cierre de Guia con errores?,",
        text:
          "Se conservan todas los cierres que esten completos, para aprobación y que no tengan ventana de descargue o sea erronea.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          me.cargando = true;
          axios
            .delete(
              "/api/hidrocarburos/cierreGuias/eliminarErrores/" + me.usuario_id
            )
            .then(() => {
              me.filtros.nPlaca = null;
              me.filtros.nGuia = null;
              me.filtros.observacion = null;
              me.ventana_descargue = null;
              me.producto_liquido = null;
              me.empresa = null;
              me.filtros.novedad_1 = null;
              me.filtros.novedad_2 = null;
              me.filtros.fecha_enturne = null;
              me.filtros.fecha_descargue = null;
              me.cargarCierre();
              me.formActualizar = false;
              me.$swal({
                icon: "success",
                title: "Se eliminaron:" + con_error + " Registros con errores ",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 6000,
                timerProgressBar: true,
              });
              me.cargando = false;
            });
        }
      });
    },

    descartarTotal() {
      let me = this;
      this.$swal({
        title: "Esta seguro de descartar el cierre masivo?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          me.cargando = true;
          axios
            .delete(
              "/api/hidrocarburos/cierreGuias/eliminarTodo/" + me.usuario_id
            )
            .then(() => {
              me.filtros.nPlaca = null;
              me.filtros.nGuia = null;
              me.filtros.observacion = null;
              me.filtros.novedad_1 = null;
              me.filtros.novedad_2 = null;
              me.filtros.fecha_enturne = null;
              me.filtros.fecha_descargue = null;
              me.ventana_descargue = null;
              me.producto_liquido = null;
              me.empresa = null;
              me.cargarCierre();
              me.formActualizar = false;
              me.visible = 0;
              me.visibleCuadro = 0;
              me.file = null;
              me.cargarCierreGuias = [];
              me.$swal({
                icon: "success",
                title: "Se eliminaron los cierres de de manera exitosa...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              me.cargando = false;
            });
        }
      });
    },

    sinCierre() {
      this.formActualizar = false;
    },

    aprobarCierre() {
      return this.$router.replace("/Hidrocarburos/CierreGuiaAprobar");
    },

    guardarCierre() {
      if (!this.$v.form.$invalid) {
        axios({
          method: "PUT",
          url: "/api/hidrocarburos/cierreGuias",
          data: this.form,
        })
          .then((response) => {
            this.cargarCierre();
            this.formActualizar = false;
            this.$swal({
              icon: "success",
              title: "Se realizo el cambio exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    verFormActualizar(cierre_id) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      (this.idActualizar = cierre_id),
        (this.form.id = this.cargarCierreGuias.data[cierre_id].id),
        (this.form.guia_id = this.cargarCierreGuias.data[cierre_id].guia_id),
        (this.form.barriles_gov = this.cargarCierreGuias.data[
          cierre_id
        ].barriles_gov),
        (this.form.barriles_gsv = this.cargarCierreGuias.data[
          cierre_id
        ].barriles_gsv),
        (this.form.barriles_nsv = this.cargarCierreGuias.data[
          cierre_id
        ].barriles_nsv),
        (this.form.api = this.cargarCierreGuias.data[cierre_id].api),
        (this.form.porcentaje_bsw = this.cargarCierreGuias.data[
          cierre_id
        ].porcentaje_bsw),
        // Mostramos el formulario
        (this.formActualizar = true);
    },

    descartar(idCierre) {
      this.$swal({
        title: "Esta seguro de eliminar esta Cierre de Guia?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/cierreGuias/" + idCierre)
            .then(() => {
              this.cargarCierre();
              this.formActualizar = false;
              this.$swal({
                icon: "success",
                title: "Se elimino el cierre de manera exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    async getNovedades() {
      axios.get("/api/lista/52").then((response) => {
        this.listasForms.novedades = response.data;
      });
    },

    async cargarCierre(page = 1) {
      this.cargando = true;
      let me = this;

      let ventanaDescargue = null;
      let empresa_id = null;
      let producto_liquido_id = null;
      if (me.ventana_descargue !== null) {
        ventanaDescargue = me.ventana_descargue.id;
      }
      if (me.empresa !== null) {
        empresa_id = me.empresa.id;
      }
      if (me.producto_liquido !== null) {
        producto_liquido_id = me.producto_liquido.id;
      }
      await axios
        .get("/api/hidrocarburos/cargarCierreGuias?page=" + page, {
          params: {
            user_id: me.usuario_id,
            nPlaca: me.filtros.nPlaca,
            nGuia: me.filtros.nGuia,
            novedad_1: me.filtros.novedad_1,
            novedad_2: me.filtros.novedad_2,
            fecha_enturne: me.filtros.fecha_enturne,
            fecha_descargue: me.filtros.fecha_descargue,
            observacion: me.filtros.observacion,
            ventanaDescargue: ventanaDescargue,
            empresa_id: empresa_id,
            producto_liquido_id: producto_liquido_id,
          },
        })
        .then(async (response) => {
          me.cargarCierreGuias = response.data.data;
          me.cumple = response.data.cumple;
          if (me.cargarCierreGuias.data[0]) {
            me.visible = 1;
            me.visibleCuadro = 1;
          } else {
            if (
              me.filtros.nPlaca !== null ||
              me.filtros.observacion !== null ||
              me.ventana_descargue !== null ||
              me.filtros.nGuia !== null ||
              me.filtros.novedad_1 !== null ||
              me.filtros.novedad_2 !== null ||
              me.filtros.fecha_enturne != null ||
              me.filtros.fecha_descargue != null ||
              me.producto_liquido !== null ||
              me.empresa !== null
            ) {
              me.visible = 1;
              me.visibleCuadro = 1;
            } else {
              me.visible = 0;
              me.visibleCuadro = 0;
            }
          }
          await this.informacionTotal();

          me.cargando = false;

          let serv_alert = "<table class='table'>";
          serv_alert += "<tr>";
          serv_alert += "<th>Producto</th>";
          serv_alert += "<th>Ventana Descargue</th>";
          serv_alert += "<th>Fecha</th>";
          serv_alert += "<th>Total producto</th>";
          serv_alert += "<th>Sumatoria producto</th>";
          serv_alert += "<th>Total guias</th>";
          serv_alert += "<th>Conteo guias</th>";
          serv_alert += "</tr>";
          let totales = response.data.totales;
          if (totales.length > 0) {
            for (let x = 0; x < totales.length; x++) {
              serv_alert += "<tr>";
              serv_alert += `<td>${totales[x].producto_liquido}</td>`;
              serv_alert += `<td>${totales[x].ventana_descargue}</td>`;
              serv_alert += `<td>${totales[x].fecha}</td>`;
              if (totales[x].tot_guias == undefined) {
                serv_alert += `<td>0</td>`;
              } else {
                serv_alert += `<td>${totales[x].tot_nsv}</td>`;
              }

              serv_alert += `<td>${parseFloat(totales[x].barriles_nsv).toFixed(
                2
              )}</td>`;
              if (totales[x].tot_guias == undefined) {
                serv_alert += `<td>0</td>`;
              } else {
                serv_alert += `<td>${totales[x].tot_guias}</td>`;
              }
              if (totales[x].cant_guia == undefined) {
                serv_alert += `<td>0</td>`;
              } else {
                serv_alert += `<td>${totales[x].cant_guia}</td>`;
              }

              serv_alert += "</tr>";
            }
            serv_alert += "</table>";
            await this.$swal({
              title: "Informacion de cierre con totales.",
              html: serv_alert,
              icon: response.data.cumple == 1 ? "success" : "error",
              showCancelButton: false,
              confirmButtonText: "Ok",
              width: "800px",
            });
          } else {
            this.$swal({
              icon: "error",
              title: "Recuerde por favor realizar los cierres totales",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        });
    },

    async buscarVentana() {
      let me = this;
      var url = "api/hidrocarburos/ventanasEnturnamiento/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.ventanasDescargue = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarEmpresa() {
      let me = this;
      var url = "api/admin/empresas/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.empresas = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async buscarProductoLiquido() {
      let me = this;
      var url = "api/hidrocarburos/Productos/lista";
      axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.productos_liquidos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    importarCierreGuia() {
      if (this.file != null) {
        this.cargando = true;
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("user_id", this.usuario_id);
        axios
          .post("/api/hidrocarburos/cierreGuias/importExcel", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            let result_content =
              response.data.error.length > 0
                ? `<div>
                <p class="text-danger">
                  <strong>El cierre no se cargo.</strong>
                </p>
              </div>`
                : `<div>
                <p class="text-success">
                  <strong>El cierre de guias se cargo exitosamente.</strong>
                </p>
              </div>`;
            if (response.data.error.length > 0) {
              result_content += `<h4>Errores encontrados.</h4>`;
              response.data.error.forEach(
                (err) => (result_content += `<div><small>${err}</small></div>`)
              );
            }
            this.$swal({
              title: "Resultado de la carga.",
              html: result_content,
              icon: response.data.error.length > 0 ? "error" : "success",
              showCancelButton: false,
              confirmButtonText: "Ok",
              //width: "800px",
              backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.value) {
                this.file = null;
                this.visible = 1;
                this.cargarCierre();
              }
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      } else {
        this.$swal({
          icon: "error",
          title: "Para guardar debe seleccionar al menos un archivo ",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    eliminarLink() {
      this.file = null;
    },

    elegirDirectorio() {
      this.file = this.$refs.file.files[0];
    },

    back() {
      return this.$router.replace("/Hidrocarburos");
    },

    exportarCierreGuia() {
      this.$parent.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/cierreGuias/exportExcel",
      })
        .then((response) => {
          let data = response.data;
          this.$parent.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error inesperado " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    async getListaCierre() {
      axios.get("/api/lista/74").then((response) => {
        this.listasForms.listaCierre = response.data;
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    async informacionTotal() {
      let me = this;
      me.informacion.sin_error = 0;
      me.informacion.con_diferencia = 0;
      me.informacion.sin_ventana = 0;
      me.informacion.ventana_erronea = 0;
      me.informacion.con_cierre = 0;
      me.informacion.con_error = 0;
      me.informacion.con_aprobacion = 0;
      me.informacion.cierre_guia = 0;
      me.informacion.otro_usuario = 0;
      await axios
        .get("/api/hidrocarburos/cierreGuias/informacionTotal", {
          params: {
            user_id: me.usuario_id,
          },
        })
        .then(async (response) => {
          me.informacionTotalCierre = response.data;
          await me.obtenerInformacion();
        });
    },

    async obtenerInformacion() {
      this.informacionTotalCierre.forEach(async (cierre) => {
        if (cierre.observacion == 28) {
          this.informacion.sin_error++;
        }

        if (cierre.observacion == 27) {
          this.informacion.con_diferencia++;
        }
        if (cierre.observacion == 26 || cierre.observacion == 25) {
          this.informacion.ventana_erronea++;
        }

        if (cierre.observacion == 24 || cierre.observacion == 23) {
          this.informacion.sin_ventana++;
        }

        if (cierre.observacion == 3) {
          //ya cerrado
          this.informacion.con_cierre++;
        }

        if (cierre.observacion == 4) {
          // aprobacion en proceso
          this.informacion.con_aprobacion++;
        }

        if (cierre.observacion == 5 || cierre.observacion == 6) {
          this.informacion.cierre_guia++;
        }

        if (
          cierre.observacion == 1 ||
          cierre.observacion == 2 ||
          (cierre.observacion >= 7 && cierre.observacion <= 22)
        ) {
          this.informacion.con_error++;
        }
        if (cierre.observacion == 0) {
          this.informacion.otro_usuario++;
        }
      });
    },

    getBackgroundClass(indiceResum) {
      return indiceResum % 2 === 0 ? "bg-lightred" : "bg-lightgreen"; // Alterna clases de fondo en función del índice
    },
  },
  async mounted() {
    await this.cargarCierre();
    await this.getNovedades();
    await this.getListaCierre();
    await this.buscarVentana();
    await this.buscarEmpresa();
    await this.buscarProductoLiquido();
  },
};
</script>
<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.info-box {
  background-color: #343a40;
  color: #fff;
}

.info-box-icon {
  border-radius: 0.25rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.875rem;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 70px;
}

.bg-lightred {
  background-color: #ffffcc; /* Cambia el valor alpha (0.5) para ajustar la opacidad */
}

.bg-lightgreen {
  background-color: #e5f7e0; /* Cambia el valor alpha (0.5) para ajustar la opacidad */
}
</style>
